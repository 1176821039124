.covenant-table .ant-table-thead .ant-table-cell {
    background-color: #6B7280 !important;
    color: white;
    padding: 16px;
    width: 160px
}

.covenant-table .ant-table-thead > tr > th:first-child {
    background-color: #374151 !important;
    color: white;
    height: 55px !important;
}

.covenant-table .ant-table-thead > tr > th:last-child {
    background-color: white !important;
    color: white;
    height: 55px !important;
}


.covenant-table .ant-table-row-expand-icon {
    align-self: center;
    padding-right: 15px;
    margin-right: 0px;
}
